import React from "react";
import {
	ApplicationFormText,
	ApplicationTeamSizeSelect,
} from "@components/ApplicationStepPage/ApplicationStepPage";
import {} from "@components";
import {
	useInstitutionFormState,
	INSTITUTION_FORM_STEPS,
} from "../_useInstitutionFormState";
import { InstitutionStepPage } from "../_InstitutionStepPage";

const InstitutionDetails: React.FunctionComponent = () => {
	const {
		getInputProps,
		navigateToStep,
		setForceShowError,
		theseFieldsHaveValues,
	} = useInstitutionFormState(INSTITUTION_FORM_STEPS.INSTITUTION.formStep);

	const onSubmit = () => {
		setForceShowError(true);

		// Make sure everything has a value
		if (
			theseFieldsHaveValues(
				"0-2/institution_name",
				"0-2/institution_website_url",
				"0-2/institution_location",
				"team_size_range"
			)
		) {
			navigateToStep(INSTITUTION_FORM_STEPS.USE.formStep);
		}
	};

	return (
		<InstitutionStepPage
			currentStepIndex={INSTITUTION_FORM_STEPS.INSTITUTION.formStep}
			onButtonClick={onSubmit}
			onBackClick={() =>
				navigateToStep(INSTITUTION_FORM_STEPS.CONTACT.formStep)
			}
		>
			<ApplicationFormText
				{...getInputProps("0-2/institution_name")}
				required
			/>
			<ApplicationFormText
				{...getInputProps("0-2/institution_website_url")}
				required
			/>

			<ApplicationFormText
				{...getInputProps("0-2/institution_location")}
				required
			/>
			<ApplicationTeamSizeSelect {...getInputProps("team_size_range")} />
		</InstitutionStepPage>
	);
};

export default InstitutionDetails;
